import { Box, Button, Typography, useTheme } from "@mui/material";
// import { DataGrid } from "@mui/x-data-grid";
import { tokens } from "../../theme";
// import { mockDataTeam } from "../../data/mockData";
import AdminPanelSettingsOutlinedIcon from "@mui/icons-material/AdminPanelSettingsOutlined";
import LockOpenOutlinedIcon from "@mui/icons-material/LockOpenOutlined";
import SecurityOutlinedIcon from "@mui/icons-material/SecurityOutlined";
import Header from "../../components/Header";
import { Link } from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import RemoveRedEyeIcon from '@mui/icons-material/RemoveRedEye';

const Team = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const columns = [
    { field: "id", headerName: "ID" },
    {
      field: "name",
      headerName: "Name",
      flex: 1,
      cellClassName: "name-column--cell",
    },
    {
      field: "age",
      headerName: "Age",
      type: "number",
      headerAlign: "left",
      align: "left",
    },
    {
      field: "phone",
      headerName: "Phone Number",
      flex: 1,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 1,
    },
    {
      field: "accessLevel",
      headerName: "Access Level",
      flex: 1,
      renderCell: ({ row: { access } }) => {
        return (
          <Box
            width="60%"
            m="0 auto"
            p="5px"
            display="flex"
            justifyContent="center"
            backgroundColor={
              access === "admin"
                ? colors.greenAccent[600]
                : access === "manager"
                  ? colors.greenAccent[700]
                  : colors.greenAccent[700]
            }
            borderRadius="4px"
          >
            {access === "admin" && <AdminPanelSettingsOutlinedIcon />}
            {access === "manager" && <SecurityOutlinedIcon />}
            {access === "user" && <LockOpenOutlinedIcon />}
            <Typography color={colors.grey[100]} sx={{ ml: "5px" }}>
              {access}
            </Typography>
          </Box>
        );
      },
    },
  ];

  return (
    <Box m="20px">
      <Box display="flex" justifyContent="space-between" alignItems="center">
        <Header title="USERS" subtitle="User List" />

        <Box>
          <Button
            sx={{
              backgroundColor: colors.blueAccent[700],
              color: colors.grey[100],
              fontSize: "14px",
              fontWeight: "bold",
              padding: "10px 20px",
            }}
          >
            <Link to="/createuser" className="text-decoration-none text-white">
              + Create Users
            </Link>
          </Button>
        </Box>
      </Box>
      <Box
        m="40px 0 0 0"
        height="75vh"
        sx={{
          "& .MuiDataGrid-root": {
            border: "none",
          },
          "& .MuiDataGrid-cell": {
            borderBottom: "none",
          },
          "& .name-column--cell": {
            color: colors.greenAccent[300],
          },
          "& .MuiDataGrid-columnHeaders": {
            backgroundColor: colors.blueAccent[700],
            borderBottom: "none",
          },
          "& .MuiDataGrid-virtualScroller": {
            backgroundColor: colors.primary[400],
          },
          "& .MuiDataGrid-footerContainer": {
            borderTop: "none",
            backgroundColor: colors.blueAccent[700],
          },
          "& .MuiCheckbox-root": {
            color: `${colors.greenAccent[200]} !important`,
          },
        }}
      >
        {/* <DataGrid checkboxSelection rows={mockDataTeam} columns={columns} /> */}
        <div className="table-responsive">
          <table className="table accordion table-dark">
            <thead>
              <tr>
                <th scope="col">S. No.</th>
                <th scope="col">Company Name</th>
                <th scope="col">Address</th>
                <th scope="col">Subscription Package</th>
                <th scope="col">Action</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <th scope="row">1</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">2</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">3</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">4</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">5</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
              <tr>
                <th scope="row">6</th>
                <td>Lorem Ipsum</td>
                <td>1/2 Mark otto, Ispum</td>
                <td>Platinum</td>
                <td>
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <RemoveRedEyeIcon />
                  </button>
                  &nbsp;
                  <button type="button" className="btn btn-link text-white" data-bs-toggle="modal" data-bs-target="#exampleModal">
                    <DeleteIcon />
                  </button>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        {/* <!-- Modal --> */}
        <div className="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div className="modal-dialog modal-dialog-centered">
            <div className="modal-content">
              <div className="modal-header">
                <h5 className="modal-title" id="exampleModalLabel">User</h5>
                <button type="button" className="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div className="modal-body">
                Comapny Name:<br />
                Address:<br />
                Subscription Package:
              </div>
              <div className="modal-footer">
                <button type="button" className="btn btn-secondary" data-bs-dismiss="modal">Close</button>
                <button type="button" className="btn btn-primary">Save changes</button>
              </div>
            </div>
          </div>
        </div>
      </Box>
    </Box>
  );
};

export default Team;
