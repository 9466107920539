import { Box, IconButton, Typography, useTheme, Button } from "@mui/material";
import { tokens } from "../../theme";

import { Link } from 'react-router-dom';
import Header from "../../components/Header";

const Dashboard = () => {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    return (
        <Box m="20px">
            {/* HEADER */}
            <Box display="flex" justifyContent="space-between" alignItems="center">
                <Header title="Subscription" subtitle="Subscriptions" />

                <Box>
                    <Button
                        sx={{
                            backgroundColor: colors.blueAccent[700],
                            color: colors.grey[100],
                            fontSize: "14px",
                            fontWeight: "bold",
                            padding: "10px 20px",
                        }}
                    >
                        <Link to="/createsubscription" className="text-decoration-none text-white">
                            + Create Subscription
                        </Link>
                    </Button>
                </Box>
            </Box>

            {/* GRID & CHARTS */}
            <Box
                gridColumn="span 8"
                gridRow="span 2"
                backgroundColor={colors.primary[400]}
            >
                <Box
                    mt="25px"
                    p="0 30px"
                    display="flex "
                    justifyContent="space-between"
                    alignItems="center"
                >

                </Box>
            </Box>
            <Box
                display="grid"
                gridTemplateColumns="repeat(12, 1fr)"
                gap="20px"
            >
                {/* ROW 1 */}
                <Box className="p-3"
                    gridColumn="span 4"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    justifyContent="center"
                >
                    <Box>
                        <Header title="Silver" subtitle=" Lorem Ipsum dolor imtet" />
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Button className="mt-3"
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            $50
                        </Button>
                    </Box>
                </Box>
                <Box className="p-3"
                    gridColumn="span 4"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    justifyContent="center"
                >
                    <Box>
                        <Header title="Platinum" subtitle=" Lorem Ipsum dolor imtet" />
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Button className="mt-3"
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            $50
                        </Button>
                    </Box>
                </Box>
                <Box className="p-3"
                    gridColumn="span 4"
                    backgroundColor={colors.primary[400]}
                    display="flex"
                    justifyContent="center"
                >
                    <Box>
                        <Header title="Gold" subtitle=" Lorem Ipsum dolor imtet" />
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Typography
                            variant="h5"
                            fontWeight="600"
                            color={colors.grey[100]}
                        >
                            Lorem Ipsum dolor imtet
                        </Typography>
                        <Button className="mt-3"
                            sx={{
                                backgroundColor: colors.blueAccent[700],
                                color: colors.grey[100],
                                fontSize: "14px",
                                fontWeight: "bold",
                                padding: "10px 20px",
                            }}
                        >
                            $50
                        </Button>
                    </Box>
                </Box>
            </Box>
        </Box>
    );
};

export default Dashboard;
